




























































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import axios from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import Ticket16 from '@carbon/icons-vue/es/ticket/16'
import DocumentAttachment16 from '@carbon/icons-vue/es/attachment/16'
import CenterToFit16 from '@carbon/icons-vue/es/fit-to-screen/16'
import Printer16 from '@carbon/icons-vue/es/printer/16'
import Catalog16 from '@carbon/icons-vue/es/catalog/16'
import isEmpty from 'lodash/isEmpty'

import TablePagination from '@/partials/tables/Pagination.vue'
import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'
import debounce from 'lodash/debounce'
import moment from 'moment/moment'

@Component({
  computed: {
    moment () {
      return moment
    }
  },
  components: {
    GenericModalDelete,
    Catalog16,
    DocumentAttachment16,
    TrashCan16,
    Ticket16,
    Edit16,
    CenterToFit16,
    Printer16,
    TablePagination
  }
})
export default class EventSubscriptionsTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: String }) eventId!: string
  apiUrl = process.env.VUE_APP_API_URL
  statusFilter = this.$route.query.status ?? 'unpaid'
  subscriptions = []
  totalSubscriptions = 0
  filteredData: Array<EventSubscription> = []
  deleteModalIsOpened = false
  selectedSubscription = {
    id: '',
    nameFull: ''
  }

  event = {
    title: '',
    id: '',
    site: ''
  }

  created () {
    axios.get(`/event/${this.eventId}`)
      .then(response => {
        this.event = camelCaseKeys(response.data.data, { deep: true })
      })
  }

  fetchSubscriptions (page = 1, perPage = 20, search = '') {
    const perPageSnake = snakecaseKeys({ perPage })

    axios.get(`/event/${this.eventId}/subscriptions`, {
      params: {
        'page[number]': page,
        status: this.statusFilter,
        search,
        ...perPageSnake
      }
    })
      .then(response => {
        this.subscriptions = this.filteredData = camelCaseKeys(response.data.data, { deep: true })
        this.totalSubscriptions = response.data.total
      })
  }

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Inscricão foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)

    this.fetchSubscriptions()
  }

  onPageChange (pagination: Pagination) {
    this.fetchSubscriptions(pagination.page, pagination.length)
  }

  @Watch('statusFilter')
  onStatusFilterChange (value: string) {
    if (this.$route.query.status !== value) {
      this.$router.push({ query: { status: value !== '' ? value : undefined } })
    }
  }

  @Watch('$route')
  onRouteChange () {
    this.fetchSubscriptions()
  }

  search = debounce((search) => {
    this.fetchSubscriptions(1, 20, search)
  }, 300)

  get deleteUrl () {
    return isEmpty(this.selectedSubscription) ? '' : `/event/${this.eventId}/subscription/${this.selectedSubscription.id}`
  }
}
